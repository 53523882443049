@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-header{box-sizing:border-box;width:500px;padding:45px}.main-header h1{font-size:65px;font-weight:900;margin:0;line-height:50px}.main-header button{background:transparent;color:#00d6d6;border:1px solid #00d6d6;border-radius:50%;width:40px;height:40px;margin:10px;font-size:25px;cursor:pointer}.main-header button:active,.main-header button:focus{outline:none}.main-header button:disabled{color:#70ffff;border-color:#70ffff;cursor:default}

.card{background-size:cover;background-position:center;box-sizing:border-box;height:540px;position:relative;width:325px}.card .card-wrapper{background:white;bottom:0;height:175px;left:15px;overflow:hidden;position:absolute;transition:all 0.4s ease-in;width:285px}.card .card-wrapper .card-wrapper-relative{position:relative;height:100%}.card .card-wrapper .card-wrapper-relative .card-header{line-height:26px;padding:16px}.card .card-wrapper .card-wrapper-relative .card-header h2{margin:0;font-size:30px;text-transform:uppercase}.card .card-wrapper .card-wrapper-relative .card-content{padding:0 16px}.card .card-wrapper .card-wrapper-relative .card-footer{position:absolute;bottom:0;height:50px;background:linear-gradient(to top, white, rgba(255,255,255,0.1));width:100%}.card .card-wrapper .card-wrapper-relative .card-footer .card-open-button{background-color:#00d6d6;display:flex;align-items:center;justify-content:center;font-size:34px;font-weight:500;width:37px;height:37px;bottom:0;right:0;position:absolute;border:none;cursor:pointer}.card .card-wrapper .card-wrapper-relative .card-footer .card-open-button:active,.card .card-wrapper .card-wrapper-relative .card-footer .card-open-button:focus{outline:none}.card .card-wrapper .card-wrapper-relative .card-footer .card-open-button .card-open-button-icon{color:white;transition:transform 0.3s linear}.card.opened .card-wrapper{height:100%;width:100%;left:0}.card.opened .card-open-button-icon{transform:rotate(45deg)}

.cards-container{width:500px;height:540px;overflow:hidden;position:relative}.cards-container .cards-container-hidden{display:flex;justify-content:space-between;width:1020px;transition:transform 0.2s linear}.cards-container:after,.cards-container:before{position:absolute;height:100%;width:90px;top:0;display:block;content:' ';z-index:1}.cards-container:before{left:0;background:linear-gradient(to right, white, rgba(255,255,255,0))}.cards-container:after{right:0;background:linear-gradient(to left, white, rgba(255,255,255,0))}

*{color:#39424d;font-family:'Rubik', sans-serif;font-weight:300}h1,h2{letter-spacing:-2px;font-weight:600}.main-container{display:flex;justify-content:space-around}

