@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;900&display=swap');
@import './sass/variables.scss';

* {
  color: $font-color;
  font-family: 'Rubik', sans-serif;
  font-weight: 300;
}
h1,h2 {
  letter-spacing: -2px;
  font-weight: 600;
}

.main-container {
  display: flex;
  justify-content: space-around;
}